// extracted by mini-css-extract-plugin
export var blogCardContainer = "servicepage-module--blogCardContainer--iyW7h";
export var blogCardDescription = "servicepage-module--blogCardDescription--FSdLz";
export var blogCardDetails = "servicepage-module--blogCardDetails--SSdX9";
export var blogCardMeta = "servicepage-module--blogCardMeta--swF8H";
export var blogCardPhoto = "servicepage-module--blogCardPhoto--H1qDs";
export var blogCardTitle = "servicepage-module--blogCardTitle--5B5y-";
export var ctaButton = "servicepage-module--ctaButton--SzpAs";
export var flexContainer = "servicepage-module--flexContainer--nXQZN";
export var flexImage = "servicepage-module--flexImage--T-J1j";
export var inlineLink = "servicepage-module--inlineLink--JR16l";
export var linkedArticleContainer = "servicepage-module--linkedArticleContainer--Ni-Fd";
export var linkedArticleImage = "servicepage-module--linkedArticleImage--wUGrN";
export var linkedArticleTitle = "servicepage-module--linkedArticleTitle--xB8Ab";
export var reviewsContainer = "servicepage-module--reviewsContainer--g6BZQ";
export var servicePageContainer = "servicepage-module--servicePageContainer--Q2Vht";
export var slickSlider = "servicepage-module--slick-slider--Ox6qO";
export var textContent = "servicepage-module--textContent--rWjww";
export var textContentHeader = "servicepage-module--textContentHeader--tujNE";
export var trustBadges = "servicepage-module--trustBadges--6zyqq";