import React, { Component } from "react"

class Reviews extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <section className="reviews">
        <h2>Customer Reviews</h2>
        <div className="reviewContainer">
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p className="reviewText">
              "I have built two homes and have done a huge renovation on
              another. I have worked with numerous tradesmen over the years.
              Pivot Painters did a stellar job! They arrived on time, very
              pleasant to have in my home and were very clear and fair in their
              pricing. We will definitely hire them for our next big project!"
            </p>
            <p className="heavyLato">-Jennifer Warden</p>
          </div>
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p className="reviewText">
              "They are great!! They always there on time. Very dedicated, you
              feel like they do the job for their own house trying to get the
              best result possible! Manny and the team are awesome. I have
              nothing to say but highly recommend them for your project."
            </p>
            <p className="heavyLato">-Mohammed Osman</p>
          </div>
          <div className="review">
            <div className="fiveStars">
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i> <i className="fas fa-star"></i>{" "}
              <i className="fas fa-star"></i>
            </div>
            <p className="reviewText">
              "We are very pleased we chose Pivot Painters (Manny). They did our
              living room, Dining room , Kitchen, Halls and all the ceilings.
              All one open area with vaulted ceilings. They went above and
              beyond. The whole job turned out better than expected. Beautiful
              work. I will recommend them to everyone. We will call him again."
            </p>
            <p className="heavyLato">-Bruce Dolence</p>
          </div>
        </div>
      </section>
    )
  }
}

export default Reviews
