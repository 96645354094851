import Layout from "../components/layout"
import Hero from "../components/hero"
import React from "react"
import Reviews from "../components/reviews"
import { useStaticQuery, graphql, Link } from "gatsby"
import SEO from "../components/seo"
import OptInForm from "../components/optInForm"
import * as ServicePageStyles from "../styles/servicepage.module.scss"
import PortfolioCarousel from "../components/portfolioCarousel"
import GoogleReviewCarousel from "../components/google-review-carousel"

const CommercialPaintingPage = () => {
    const data = useStaticQuery(graphql`
    query CommercialQuery {
      bannerImage: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/commercial-painting-pivot-painters-header"
        }
      ) {
        secure_url
      }
      portfolioImg1: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/commercial-painters-chicago"
        }
      ) {
        secure_url
      }
      portfolioImg2: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-1430-west-cuyler-avenue-chicago-3"
        }
      ) {
        secure_url
      }
      portfolioImg3: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-2328-north-rockwell-street-chicago-2"
        }
      ) {
        secure_url
      }
      portfolioImg4: cloudinaryMedia(
        public_id: {
          eq: "pivot-painters-chicago/portfolio/pivot-painters-5858-north-broadway-street-chicago-2"
        }
      ) {
        secure_url
      }
      bmIcon: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/icons/bm" }) {
        secure_url
      }
      swIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/sherwin-williams" }
      ) {
        secure_url
      }
      googleIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/5-Google-Review-Badge" }
      ) {
        secure_url
      }
      angiIcon: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/icons/toprated-solid-border" }
      ) {
        secure_url
      }
    }
  `)
    return (
        <Layout>
            <SEO
                title="Commercial Painting Chicago | Pivot Painters Chicago IL"
                description="Commercial Painting in Chicago with Pivot Painters, we keep your business looking good so you can focus on your customers! Get a bid for your business today."
            />
            <Hero
                name="Commercial Painting Chicago"
                src={data.bannerImage.secure_url}
                subtitle="Maintain Your Brand"
            />
            <div className={ServicePageStyles.servicePageContainer}>
                <section className={ServicePageStyles.trustBadges}>
                    <img
                        src={data.bmIcon.secure_url}
                        alt="Pivot Painters Chicago - Benjamin Moore Icon"
                    />
                    <img
                        src={data.swIcon.secure_url}
                        alt="Pivot Painters Chicago - Sherwin Williams Icon"
                    />
                    <img
                        src={data.angiIcon.secure_url}
                        alt="Pivot Painters Chicago - Angi Leads Icon"
                    />
                    <img
                        src={data.googleIcon.secure_url}
                        alt="Pivot Painters Chicago - Google Icon"
                    />
                </section>

                <div className={ServicePageStyles.flexContainer}>
                    <PortfolioCarousel
                        captions="Commercial Painting Chicago - Pivot Painters Chicago"
                        images={[
                            data.portfolioImg1.secure_url,
                        ]}
                    />
                    <div className={ServicePageStyles.textContent}>
                        <h2 className={ServicePageStyles.textContentHeader}>
                            Commercial Painting <span className="heavyLato">Done Right.</span>
                        </h2>
                        As an outstanding commercial painting service in chicago we understand exactly what it means to 
                        provide stress free painting for local businesses. We are easy to work with using our in house tech to keep things running smoothly
                        from start to finish. 
                        <br></br>
                        <br></br>
                        Commercial painting is a great way to keep your business looking fresh. Brand image is so important
                        especially now in our modern world. Keep your business looking clean today!
                        <br></br>
                        <br></br> Of course we do property maintenance for real estate owners, property management companies, etc, Pivot Painters is here to keep things pristine and
                        provide the{" "}
                        <strong>best commercial painting Chicago has to offer!</strong>
                        <Link className={ServicePageStyles.ctaButton} to="/request-a-quote">
                            Schedule A Free Estimate
                        </Link>
                    </div>
                </div>
                <div className={ServicePageStyles.reviewsContainer}>
                    <h2 className={ServicePageStyles.textContentHeader}>
                        What Past Customers Are Saying
                    </h2>
                    <GoogleReviewCarousel />
                </div>

                <div className={ServicePageStyles.textContent}>
                    <h2 className={ServicePageStyles.textContentHeader}>
                        Why Hire Pivot Painters?
                    </h2>
                    We are number 1 when its comes to commercial painting. Why? Because we aren't the run of the mill
                    painting company we use modern technology, we are young but experienced and use that to our advantage.
                    Avoid the cliche, slow, and hard to work with Joe. Take a look at our reviews and give us a call at (312) 854-9180 today and see why we are different.
                    <br></br>
                    <br></br>
                    <ul>
                        <li>
                            <i className="fas fa-brush"></i>
                            <p>Trusted And Experienced Professionals</p>
                        </li>
                        <li>
                            <i className="fas fa-brush"></i>
                            <p>Use Premium Quality Materials</p>
                        </li>
                        <li>
                            <i className="fas fa-brush"></i>
                            <p>Constant And Reliable Communication</p>
                        </li>
                        <li>
                            <i className="fas fa-brush"></i>
                            <p>Seamless Experience</p>
                        </li>
                    </ul>
                    <OptInForm></OptInForm>
                </div>

                <Reviews></Reviews>
            </div>
        </Layout>
    )
}

export default CommercialPaintingPage
