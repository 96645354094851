import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import * as PortfolioCarouselStyles from "../styles/portfolio-carousel.module.scss"

const PortfolioCarousel = ({ images, captions }) => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    className: `${PortfolioCarouselStyles.carouselContainer}`,
    arrows: false,
  }

  return (
    <Slider {...carouselSettings}>
      {images.map((image, index) => {
        return (
          <div key={index}>
            <img
              src={image}
              alt={captions}
              className={PortfolioCarouselStyles.portfolioImage}
            />
          </div>
        )
      })}
    </Slider>
  )
}

export default PortfolioCarousel
