import React, { Component } from "react"
import { Link } from "gatsby"

class Hero extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const imgSrc = {
      backgroundImage:
        "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)), url(" +
        this.props.src +
        ")",
    }

    return (
      <div className="heroContainer" style={imgSrc}>
        <h1>{this.props.name}</h1>
        {this.props.subtitle && (
          <h3 className="heroSubtitle">{this.props.subtitle}</h3>
        )}
        <Link
          to={this.props.quoteLink ?? "/request-a-quote"}
          className="requestQuoteLink"
        >
          Get A Free Quote
        </Link>
      </div>
    )
  }
}

export default Hero
